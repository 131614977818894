.background {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  background-image: url('https://firebasestorage.googleapis.com/v0/b/verdant-evolution-webpage.appspot.com/o/Human%20And%20Robot.jpg?alt=media');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.background h1 {
  position: relative;
  z-index: 2;
  color: #60AD2C;
  font-size: 5em;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.background span {
  position: relative;
  z-index: 2;
  color: #F0F0F0; /* Set your desired color */
  font-size: 2em; /* Set your desired size */
  text-align: center;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}