.consulting-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  max-width: 70%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 1000px) {
  .consulting-section {
    grid-template-columns: 1fr;
    max-width: 100%;
  }

  .consulting-section .column {
    max-width: 375px;
    margin: 0 auto;
  }
}

.section-heading h1 {
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
  color: #60AD2C;
  margin-bottom: 20px;
}

.column img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.column h2 {
  color: #60AD2C;
  text-align: center;
  font-weight: bold;
}

.column span {
  text-align: left;
  font-weight: bold;
}

.image-container {
  width: 100%; /* or any specific width */
  height: 0;
  padding-bottom: 66.7557%; /* 100 / 1.498 */
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); 
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
}