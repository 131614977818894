.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
  font-size: 0.9em;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer p {
  margin: 5px 0;
}
