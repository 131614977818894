.contact-form-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background: #f8f8f8;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.contact-form-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 2em;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #666;
  font-weight: 600;
}

.input-group input,
.input-group textarea {
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  font-size: 1em;
}

.input-group textarea {
  height: 120px;
}

button {
  width: 100%;
  padding: 15px;
  border: none;
  background-color: #4CAF50;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 10px;
  font-weight: bold;
}

button:hover {
  background-color: #45a049;
}

.input-group input:focus,
.input-group textarea:focus,
button:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.4);
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}