.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100px;
  background-color: #333;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.logo-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: white;
}

.logo img {
  margin-left: 10px;
}

.company-name {
  font-size: 1.5em;
  font-weight: bold;
}
